import { Link } from 'gatsby';
import React from 'react';

import SBLI from '../images/sbli-sm.jpg';
import wnslogo from '../images/wnslogo.svg';


function LifePartners() {
  return (
    <div className="bgFFF aboutPage menuPartners flexRow LifePartnerPadding">
      <div className="flexRow justify-content-between makeColumn">
        <div className="flexColumn touch mt-lg-3 mt-md-3 mt-0">
          <div className="title">
                        Who we work with
          </div>

          <div className="flexRow makeColumn">
            <div className="flexColumn" >
              <div className="flexRow nav-partner">
                <img src="https://devuiassetscdn.getmybubble.io/images/next-by-pacific-life-1.png" className="imgBox" alt="pacific life logo" />

                <div className="flexColumn">
                  <div className="title">
                                        Next Term Life
                  </div>

                  <p className="para">
                                        Pacific Life is one of the most dependable and experienced insurers in the industry.
                  </p>
                </div>
              </div>

              <Link className="MobilePadding" to="/next-by-pacific-life">
                <div className="learnMore" role="button">
                                    View Details
                  <div className="orangeTriangle"></div>
                </div>
              </Link>
            </div>

            <div className="flexColumn" >
              <div className="flexRow nav-partner">
                <img src={SBLI} alt="logo" />

                <div className="flexColumn">
                  <div className="title">
                                        SBLI
                  </div>

                  <p className="para">
                                    For more than 110 years, policyholders have been able to trust that our financial strength means we’ll be there for them when they need us most.
                  </p>
                </div>
              </div>

              <Link to="/SBLI">
                <div className="learnMore" role="button">
                                    View Details
                  <div className="orangeTriangle"></div>
                </div>
              </Link>
            </div>

            <div className="flexColumn" >
              <div className="flexRow nav-partner">
                <img src={wnslogo} alt="logo" />

                <div className="flexColumn">
                  <div className="title">
                                        W&S
                  </div>

                  {/* <p className="para">
                                    For more than 110 years, policyholders have been able to trust that our financial strength means we’ll be there for them when they need us most.
                  </p> */}
                </div>
              </div>

              <Link to="/wns">
                <div className="learnMore" role="button">
                                    View Details
                  <div className="orangeTriangle"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LifePartners;
