import { Link } from 'gatsby';
import React from 'react';

import StillWater from '../images/stillwater.jpg';

const HomePartners = () => {
  return (
    <div className="bgFFF aboutPage menuPartners flexRow HomePartnerPadding">
      <div className="flexRow justify-content-between makeColumn">
        <div className="flexColumn touch mt-lg-3 mt-md-3 mt-0">
          <div className="title">
                        Who we work with
          </div>

          <div className="flexRow makeColumn">
            <div className="flexColumn" >
              <div className="flexRow nav-partner">
                <img src={StillWater} alt="StillWater logo" />

                <div className="flexColumn">
                  <div className="title">
                                        Stillwater
                  </div>

                  <p className="para">
                                        Start protecting the place that you and your loved ones call home.
                  </p>
                </div>
              </div>

              <Link className="MobilePadding" to="/stillwater">
                <div className="learnMore" role="button">
                                    View Details
                  <div className="orangeTriangle"></div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePartners;
