import { Link } from 'gatsby';
import React from 'react';

import * as FooterStyles from '../styles/footer.module.css';

const FtImg = 'https://cdn.getmybubble.com/images/new-quote-flow/bubble-logo-white.svg';

function FooterCommon( { children } ){
  const year = new Date().getFullYear();

  return (
    <div className={FooterStyles.footerBox}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className={FooterStyles.footerLogo}>
              <Link to="/" className={FooterStyles.footerLogoLink}>

                <img src={FtImg} loading="lazy" className={FooterStyles.FtImg} alt="Bubble Logo"/></Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className={FooterStyles.footerText + ' tonerFooterText'}>
              { children }

              <p>Bubble aims to make it easy, convenient, quick and affordable for homeowners to protect their homes, loved ones and everything under their roof with smart insurance products for total assurance. Learn more about Bubble Insurance</p>

              <p className={FooterStyles.ftParaGraph}>©Bubble Insurance Solutions,&nbsp;{year} | All rights reserved</p>

              {/* <p className={FooterStyles.ftParaGraph}>Made with&nbsp;&nbsp;❤️&nbsp;&nbsp;in California.</p> */}
            </div>
          </div>

          <div className="col-12 col-md-12 fter-lst-txt">
           Bubble Insurance Solutions (“Bubble”) is a general agent for insurance companies, such as Stillwater Insurance Group, Pacific Life Insurance Company, and The Savings Bank Mutual Life Insurance Company of Massachusetts [SBLI]. Bubble is licensed as a property casualty and life insurance agency in
           CA, AZ, NJ, WA, OH, and TX. Availability and qualification for coverage, terms, rates, and discounts may vary by jurisdiction. Bubble receives compensation based on the premiums for the insurance policies it sells. Please refer to Terms and Conditions for additional information
          </div>
        </div>
      </div>
    </div >
  );

}


export default FooterCommon;
